import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

// コンテンツここ
const links = [


  {
    text: "FF14",
    url: "https://jp.finalfantasyxiv.com/lodestone/character/2966570/",
    description:
      "休止予定。",
    },
    {
      text: "Diablo4",
      url: "",
      description:
        "休止中。リリスまで。やっぱりネクロが好き。",
    },
    {
      text: "Fallout76",
      url: "",
      description:
        "休止中。弾持ちがとても良い「血濡れガトリング」を愛用していました。",
    },
  {
    text: "DQ10",
    url: "https://hiroba.dqx.jp/sc/character/111553280695",
    description:
      "最近PS版をVer6まで適用しましたが休止中。占い師120までやりました。",
  },
  {
    text: "PSO2NGS",
    url: "",
    description:
      "休止中。PSO2ではサモナー、NGSではウェイカーが好み。",
  },

  {
    text: "FGO",
    url: "",
    description:
      "少しやってる。1.5アガルタまでクリア",
  },

  {
    text: "available Machine",
    url: "/availableMachine",
    description:
      "稼働可能状態なゲーム機",
  },

]

// ここにリンクをつけておこう
const moreLinks = [
  { text: "kowori.net", url: "https://kowori.net" },
  { text: "Twitter", url: "https://twitter.com/kowori_fallout" },
  { text: "BlueSky", url: "https://bsky.app/profile/tadakori.bsky.social" },
  { text: "YouTube", url: "https://www.youtube.com/channel/UC2-6vCCrGTqCDE6tTQ9TtKw" },
]

// TRAP????
//const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`
const utmParameters = ``

const IndexPage = () => (
  <Layout>
    <div className={styles.textCenter}>
      <StaticImage
        src="../images/robot_a.png"
        loading="eager"
        width={64}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-3)` }}
      />
      <h1>
      <a href="/">kowori.net</a>
      </h1>
      <p className={styles.intro}>
        callme tadakori-san.
      </p>
    </div>

    <ul className={styles.list}>
      {links.map(link => (
        <li key={link.url} className={styles.listItem}>
          <a
            className={styles.listItemLink}
            href={`${link.url}${utmParameters}`}
          >
            {link.text} ↗
          </a>
          <p className={styles.listItemDescription}>{link.description}</p>
        </li>
      ))}
    <div className={styles.left}>
    </div>
    </ul>

    <hr />
    <div className={styles.textCenter}>
    {moreLinks.map((link, i) => (
      <React.Fragment key={link.url}>
        <a href={`${link.url}${utmParameters}`}>{link.text}</a>
        {i !== moreLinks.length - 1 && <> · </>}
      </React.Fragment>
    ))}
    </div>

  </Layout>
)


/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
